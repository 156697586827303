/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../../components/articlePage';
import FeaturedAsset from '../../components/featuredArticle';
import ReactMarkdown from '../../components/markdown';
import Link from '../../components/link';

export default function HCFSA (props) {
    const root = props.match.path;

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="dcfsa subpage"
      >
        <ReactMarkdown
          source={`
## Understanding the Health Care Flexible Spending Account
`}
        />
        <ReactMarkdown
          source={`
If you pay out-of-pocket for health, dental, vision and/or wellness expenses, you know these costs can add up! A Health Care Flexible Spending Account (HCFSA) is an optional benefit you and your family can use for health care expenses, such as co-payments and cost shares, deductibles, dental (orthodontia), vision (glasses and contact lenses) and drugstore items (sunscreen, over-the-counter medicine, and more).

The Department of Defense (DoD) offers this benefit to give military families more options in paying for care. Read the [Frequently Asked Questions (FAQ)](${root}/FAQ) for a comprehensive understanding of the benefit and its details.

Sign up to receive [Office of Financial Readiness eNewsletters](/eNews-subscriber) — or watch your inbox if you’re already a subscriber — for updates about FSAs for service members.
`}
        />
        <div className="colored-textbox dark-blue-bg">
          <div>HCFSAs will be offered to members of the uniformed services for the first time via a Special Enrollment Period occurring March 3-31, 2025. Read <Link to="/Benefits/HCFSASpecialEnrollment">this</Link> to learn all you need to know about how to enroll.</div>
        </div>
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            title="Frequently Asked Questions"
            img="/assets/img/FINRED-WebsiteImage-HCFSA-FAQ-R.jpg"
            alt="Plus sign and a series of health care icons"
            href={`${root}/FAQ`}
            link="Find Answers"
          >
            <p>
            Looking for military-specific information about HCFSAs? Check out the most frequently asked questions and answers.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HCFSA-SpecialEnrollment-FS.jpg"
            alt="Health Care Flexible Spending Account Special Enrollment Fact Sheet"
            title="What To Know for Service Members (HCFSA Fact Sheet)"
            href="/assets/downloads/FINRED-HCFSA-SpecialEnrollment-FS.pdf"
            link="Explore Here"
          >
            <p>
            Service members who have eligible health care expenses can enroll in an HCFSA and save money on these out-of-pocket expenses.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HCFSAEligibleExpenses-G.jpg"
            alt="Flexible Spending Account Eligible Expenses Guide"
            title="HCFSA Eligible Expenses Guide"
            href="/assets/downloads/FINRED-HCFSAEligibleExpenses-G.pdf"
            link="Get Started"
          >
            <p>
            Trying to determine if an HCFSA is right for your family? This user-friendly tool outlines common FSA eligible expenses in accordance with your TRICARE Plan.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HCFSA-EFMP-G.jpg"
            alt="Health Care Flexible Spending Account Exceptional Family Member Program Guide"
            title="Guide to FSAs for Families With Special Needs"
            href="/assets/downloads/FINRED-HCFSA-EFMP-G.pdf"
            link="Get Started"
          >
            <p>
            Families enrolled in the DoD Exceptional Family Member Program and USCG Special Needs Program can use this guide for detailed information on how to save on health and dependent care.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-FSA-W.jpg"
            alt="FSA on piggy bank and OneOp logo"
            title="“FSA: A Tax-Advantaged Way to Pay for Expenses” Webinar"
            href="https://oneop.org/learn/160047/"
            link="Get the Details"
          >
            <p>
            Learn more about HCFSA and DCFSA benefits during this interactive online session.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-HCFSA-P.jpg"
            alt="Save On Health Care poster"
            title="Save On Health Care"
            href="/assets/downloads/FINRED-HCFSA-P.pdf"
            link="View Now"
          >
            <p>
            Learn more about how an HCFSA can save your family money on out-of-pocket health care expenses.
            </p>
          </FeaturedAsset>
        </div>
      </Page>
    );
}
