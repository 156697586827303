/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';
import SiteTitle from './siteTitle';
import SecondaryNav from './secondaryNav';
import Header from './header';
import Footer from './footer';
import BodyScripts from './bodyScripts';
import Link from './link';
import selected from './utils/selectNav';
import Crumbs from './crumbs';
import OfficialBanner from './official-banner';
import SetTitle from './setTitle';

export default function Home (props) {
    const [title, setTitle] = useState();

    return (
      <>
        <div className="page-landing-page layout-demo " cz-shortcut-listen="true">
          <div className="usa-grid bg0">
            <SetTitle title={title} />
            <OfficialBanner />
            <SiteTitle />
            <div className="usa-nav-secondary">
              <SecondaryNav />
            </div>
            <div className="usa-top-nav">
              <Crumbs crumbs={props.crumbs ? `Home|/,${props.crumbs}` : 'Home|/'} setTitle={setTitle} />
              <div className="usa-quick-links">
                <button className={`usa-button nav-button ${selected('/pfcMap')}`}><Link to="/pfcMap" style={{ color: '#FFF' }}>Find a Personal Financial Counselor</Link></button>
                <button className={`usa-button nav-button ${selected('/SPL')}`}><Link to="https://community.apan.org/wg/finred-lrl" style={{ color: '#FFF' }}>Learning Resource Library</Link></button>
                <button className="usa-button nav-button"><Link to="https://www.milspousemoneymission.org/" style={{ color: '#FFF' }}>MilSpouse Money Mission</Link></button>
                <button className={`usa-button nav-button fwba ${selected('/FWBA')}`}><Link to="/FWBA" style={{ color: '#000' }}>Assess Your Financial Well-Being</Link></button>
              </div>

            </div>
          </div>
          <Header this />
          <main id="main-content">
            <div className="usa-main-content">

              {props.children}

            </div>
          </main>
          <Footer />
          <BodyScripts />
        </div>
      </>
    );
}

