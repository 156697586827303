/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/articlePage';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';

export default function BNA (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
      >
        <ReactMarkdown
          source={`
## Basic Needs Allowance 
The DoD implemented the [Basic Needs Allowance (BNA)](https://www.dfas.mil/MilitaryMembers/payentitlements/bna/) to help larger military households with low income better afford basic needs and increase their economic security. Eligibility is based on the [Federal Poverty Guidelines](https://www.hhs.gov/guidance/document/2025-federal-poverty-level-standards) established annually by the Department of Health and Human Services (HHS). Check back regularly for updates and information on how the 2025 guidelines will impact this year’s BNA.
            `}
          renderers={{
              link: MarkLink,
          }}
        />
      </Page>
    );
}
