/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MilitaryJourneyTPRoutes from './military-journey-tp-routes';
import UscgResourceRoutes from './uscg-resource-routes/uscg-resource-routes';
import HookUpArticle from '../components/hookUpArticle';
import SplTOC from '../components/tocs/splTOC';
import Oops404Page from '../pages/oops-404';

export default function TrainingRoutes(props) {
    const root = props.match.path;
    const crumbs = (props.crumbs ? `${props.crumbs},` : '') + 'Training|';
    const toc = () => (
        props.toc
        || (<SplTOC />)
    );

    return (
      <div>
        <Switch>
          <Route
            path={`${root}/MilitaryJourneyTouchpoints`}
            render={(routeProps) => <MilitaryJourneyTPRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/CoastGuardResource`}
            render={(routeProps) => <UscgResourceRoutes {...routeProps} crumbs={crumbs} toc={toc()} />}
          />

          {[
              // HookUpArticle(root, crumbs, toc(), 'StartingtheConversation.md', 'SPL', 'Starting the Conversation: How to Talk to Service Members About Personal Finances'),
          ]}

          <Route render={() => <Oops404Page />} />
        </Switch>
      </div>
    );
}
