/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';

import Page from '../../components/articlePage';
import ArticleCard from '../../components/articleCard';
import ReactMarkdown from '../../components/markdown';

import retirementArticles from './retirement.json';

export default function Retirement (props) {
    const root = props.match.path;
    const [selectedTags, setSelectedTags] = useState([]);

    const tags = [
        'Blended Retirement System / BRS',
        'Calculators for Retirement',
        'Checklists',
        'Employment',
        'Health Care',
        'Job Training',
        'Medical Retirement',
        'Military Spouses',
        'Planning for Retirement',
        'Planning for the Future',
        'Retirement',
        'Saving',
        'Taxes',
        'Thrift Savings Plan / TSP',
        'TRICARE',
    ];

    const handleSelectTag = (value) => () => {
        let sTags = [...selectedTags];
        if (sTags.includes(value)) {
            sTags = sTags.filter(s => s !== value);
        } else {
            sTags.push(value);
        }
        setSelectedTags(sTags);
    };

    const getTagClass = (tagText) => (selectedTags.includes(tagText) ? 'selected' : ' ');

    const filterBySelectedTags = () => (blog) => (
        selectedTags.length > 0 ? selectedTags.filter(t => blog.tags.includes(t)).length > 0 : blog
    );

    return (
      <Page
        crumbs={props.crumbs}
        toc={props.toc}
        articleClass="retirement launch-page"
      >
        <ReactMarkdown
          source={`
## Retirement
It’s never too early — or too late — to plan for retirement! Bookmark this page and return to it often for practical information on building a retirement nest egg that fits your financial goals. For easy navigation, click a term or word below to filter for specific articles and resources related to your topic. 
`}
        />
        <div className="blog-tags main-blog-page">
          {
              tags.map(t => <div className={`blog-tag ${getTagClass(t)} tag-button`} onClick={handleSelectTag(t)}>{t}</div>)
            }
        </div>

        <div>
          {
                retirementArticles.filter(filterBySelectedTags()).map((article, key) => (
                  <ArticleCard
                    key={article.title}
                    category={article.category}
                    img={article.img}
                    alt={article.alt}
                    flag={article.flag}
                    title={article.title}
                    href={article.href}
                    linkLabel={article.linkLabel}
                  >
                    {article.abstract}
                  </ArticleCard>
                ))
            }
        </div>
      </Page>
    );
}
