/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import Page from '../../components/nonTOCPage';

import Link from '../../components/link';
import MarkLink from '../../components/markLink';
import ReactMarkdown from '../../components/markdown';
import NewsletterFormControl from '../../components/newsletterFormControl';

export default function Epublications (props) {
    const root = props.match.path;
    const url = props.match && (props.match.url || props.match.path);
    let video;

    if (props.isVideo) {
        video = props.match && props.match.params.video;
    }

    return (
      <Page
        crumbs={props.crumbs}
        articleClass="launch-page mos"
      >
        <ReactMarkdown
          source={`
## EPUBLICATIONS

![Office of Financial Readiness and MilSpouse Money Mission logos over textured background](/assets/img/FINRED-WebsiteImage-eNewsLatestEditionArchive.jpg "Office of Financial Readiness and MilSpouse Money Mission logos over textured background")

### Stay Up to Date on Financial News
Get the latest news and updates directly to your inbox. Subscribe today:
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <NewsletterFormControl />

        <ReactMarkdown
          source={`

Editions of the Office of Financial Readiness and MilSpouse Money Mission ePublications are accessible through the links below. Please note: We do not update past editions. We recommend viewing the most current edition to receive the latest information.

### Your Military Money eNewsletter
This monthly eNewsletter spotlights timely information, resources and tools to help service members and their families achieve personal financial readiness in support of mission readiness.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3ce0263">Your Military Money eNewsletter Current Edition<span className="fa fa-external-link" /></Link></li>
          <li><Link to={`${root}/Archives/YourMilitaryMoney`}>Your Military Money eNewsletter Archives</Link></li>
        </ul>
        <ReactMarkdown
          source={`
### The Military Financial Service Provider eNewsletter
This monthly eNewsletter supports service providers in delivering exceptional financial knowledge and education to service members and their families. Topics include policies and laws, resources and training opportunities.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3ce020f">The Military Financial Service Provider eNewsletter Current Edition<span className="fa fa-external-link" /></Link></li>
          <li><Link to={`${root}/Archives/MilitaryFinancialServiceProvider`}>The Military Financial Service Provider eNewsletter Archives</Link></li>
        </ul>
        <ReactMarkdown
          source={`
### MilSpouse Money Mission eNewsletter
This monthly eNewsletter empowers military spouses to elevate their families by making informed money moves.
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <ul>
          <li><Link to="https://content.govdelivery.com/accounts/USDODFINRED/bulletins/3ce014a">MilSpouse Money Mission eNewsletter Current Edition<span className="fa fa-external-link" /></Link></li>
          <li><Link to={`${root}/Archives/MilSpouseMoneyMission`}>MilSpouse Money Mission eNewsletter Archives</Link></li>
        </ul>
      </Page>
    );
}
