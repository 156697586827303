/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';

import Page from '../components/page';
import MarkLink from '../components/markLink';
import FeaturedAsset from '../components/featuredArticle';
import ReactMarkdown from '../components/markdown';
import Link from '../components/link';

export default function Saving (props) {
    const root = props.match.path;

    return (
      <Page
        articleClass="launch-page"
        {...props}
      >
        <ReactMarkdown
          source={`
## Saving and Investing
Take time to learn about saving and investing — so you’ll be prepared for today’s needs and wants, while paving the way to meet your future goals and enjoy your retirement. Resources are at your fingertips to help you fine-tune or establish savings goals and find new ways to make the best use of your money.

[Retirement-Saving]()
### Retirement Saving
When you contribute money toward your retirement now, you are choosing to invest in yourself and your future. And your service has features in place to help you succeed. Develop your investment plan today and make your money — and contributions from your service — work for you later. Enjoy your retirement more because you've planned ahead!
`}
          renderers={{
              link: MarkLink,
          }}
        />

        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Coffee-F_1.jpg"
            alt="Invest in Yourself Not the Coffee Shop flyer"
            title="Invest in Yourself — Not the Coffee Shop"
            href="/assets/downloads/FINRED-Coffee-F.pdf"
            link="Read More"
          >
            <p>
            Replace your coffee shop routine with home brewing starting today and watch as the balance on your retirement fund percolates at an even faster pace. Learn more in this flyer.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-LumpSum-FS.jpg"
            alt="Blended Retirement System Lump Sum fact sheet"
            title="The BRS Lump Sum"
            href="/assets/downloads/FINRED-LumpSum-FS.pdf "
            link="Learn More"
          >
            <p>
            Learn about the Blended Retirement System lump sum option, which gives service members choices. Find information on trade-offs, timing and more in this fact sheet.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-TSPOptions-A.jpg"
            alt="Service member looking at computer next to spouse"
            title="Know Your TSP Options at Separation/Military Retirement"
            href={`${root}/TSPOptions`}
            link="Read Now"
          >
            <p>
            How to access funds in your Thrift Savings Plan depends on your post-military goals. This article provides the pros and cons of several withdrawal options.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Know the Five W's of Military Retirement Planning](${root}/5WsRetirement)
  Create your plan, set your goals and manage your finances throughout your service to make the most of your military life and your retirement. Learn more in this article.
* [The BRS Continuation Pay](/assets/downloads/FINRED-ContinuationPay-FS.pdf)
  What's continuation pay and how can it affect retirement? Explore this midcareer bonus and learn about eligibility in this fact sheet.
* [The BRS Defined Benefit (Pension)](/assets/downloads/FINRED-DefinedBenefit-FS.pdf)
  Learn about the defined benefit (pension) and how it works under the Blended Retirement System. We’ve gathered the details about it for you in this fact sheet.
* [The BRS Defined Contribution] (/assets/downloads/FINRED-DefinedContribution-FS.pdf)
  What is a defined contribution — and how do automatic and matching Thrift Savings Plan contributions add up under the Blended Retirement System? Find out in this fact sheet.
* [Thrift Savings Plan Withdrawal Considerations](${root}/EarlyTSPWithdrawals)
  Thinking about an early distribution from your Thrift Savings Plan account? Before you do anything, consider your options and their implications in this article.

[Investing]()
### Investing
Everyone has financial goals, whether it’s saving for a house, paying off debt, enjoying a comfortable retirement — or all of those and more. Find out how choosing investment opportunities that align with your current and future goals can help put them within reach.
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-529Plans-FS.jpg"
            alt="29 Education Savings Plan Fact Sheet"
            title="529 Education Savings Plans: The Basics for Service Members"
            href="/assets/downloads/FINRED-529Plans-FS.pdf"
            link="Download"
          >
            <p>
            What is a 529 plan and how does it help save for education? This fact sheet explains how the plan works and highlights special considerations for military families.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-StocksBondsMutual-A.jpg"
            alt="Hand holding money bag with red arrow pointing up"
            title="Investing Basics: Bonds, Stocks, Mutual Funds and ETFs"
            href={`${root}/StocksBondsMutualFunds`}
            link="Read Now"
          >
            <p>
            Put yourself on track for financial freedom. Read this article to learn how to use bonds, stocks, mutual funds and ETFs as tools to help you achieve your goals.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SavingInvestingOps-A.jpg"
            alt="Hands holding a mobile phone with money symbol bubbles hovering over it"
            title="Identifying Saving and Investing Opportunities"
            href={`${root}/InvestmentOps`}
            type="Infographic"
            link="Read Now"
          >
            <p>
            Can saving or investing help you meet your financial goals? Read this article to find out what makes sense for you depending on your personal goals and timeline.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={`
* [Investing Doesn't Have To Be a Puzzle](/assets/downloads/FINRED-InvestingCrossword-Q.pdf)
Crack the investing puzzle with this fun crossword comprised of common terms. Test your knowledge, then check your answers and see how you did.
* [Investing Microlearning](${root}/InvestingMicrolearning)
Learn about investing from the ground up! This growing series of short, topical videos walks you through the basics in just one minute or less.
* [Mutual Funds and Exchange-Traded Funds]( https://www.investor.gov/sites/investorgov/files/media/mutual-fundsetfs-infographic.pdf)
Diversify your assets! Explore the differences between mutual funds and exchange-traded funds in this Securities and Exchange Commission infographic.
* [Stocks and Bonds](https://www.investor.gov/sites/investorgov/files/media/stocksbonds-infographic-3.pdf)
Reduce your risk! See how stocks and bonds are different — and how both can provide diversification and reduce risk — in this Securities and Exchange Commission infographic.
* [The Advantages of Automatic Saving and Investing](https://www.investor.gov/sites/investorgov/files/media/automatic-investing-infographic.pdf)
Watch your money grow! This Securities and Exchange Commission infographic highlights the advantages of setting your wealth-building goals on autopilot.
* [The Advantages of Buy-and-Hold Investing](https://www.investor.gov/sites/investorgov/files/media/buy-and-hold-investing-infographic.pdf)
Play the long game! This Securities and Exchange Commission infographic shows how buying and holding investments may lower fees and taxes and lessen market guesswork.
* [Understanding Interest and How To Calculate It](${root}/UnderstandingInterest)
Learn the difference between simple and compound interest — and how both can impact your investments and loans.

[Additional-Information]()
### Additional Information
The earlier you start saving and investing, the better prepared you’ll be for the future. These resources can help you make smart financial choices with long-term benefits. For more information, be sure to check out the collection of resources from our trusted and featured collaborators about [saving and investing.](/ToolsAndAddRes/AdditionalRes/Saving)
`}
          renderers={{
              link: MarkLink,
          }}
        />
        <div className="usa-grid featured-assets">
          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-PlanningAhead-I.jpg"
            alt="Planning Ahead for Your Next Raise infographic"
            title="Planning Ahead for Your Next Raise"
            href="/assets/downloads/FINRED-PlanningAhead-I.pdf"
            link="Download"
          >
            <p>
            Making more money can lead to more spending. This infographic can help you learn strategies to help manage lifestyle inflation with your next pay raise.
            </p>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-Sense-Main-R.jpg"
            title="Meet Sen$e"
            alt="Sen$e logo, mobile screenshots over money textured background"
          >
            <p>
            Boost your financial knowledge with Sen$e, the DoD’s financial education app. With its tools and resources, you can stay on track with your finances, throughout your military career and beyond.
            </p>
            <div className="featured-asset-button-group">
              <Link
                to="https://play.google.com/store/apps/details?id=com.powertrain.sense&hl=en_US&gl=US"
              >
                <img
                  className="button-image"
                  src="/assets/img/Google-play-badge-adjustedsize.png"
                  alt=" "
                  title=" "
                />
              </Link>
              <Link
                to="https://apps.apple.com/us/app/sen%24e/id1443911840"
              >
                <img
                  className="button-image"
                  src="/assets/img/Download_on_the_App_Store_Badge_US-UK_blk_092917.png"
                  alt=" "
                  title=" "
                />
              </Link>
            </div>
          </FeaturedAsset>

          <FeaturedAsset
            img="/assets/img/FINRED-WebsiteImage-SavingInvesting-R.jpg"
            title="Saving and Investing Additional Resources"
            href={'/ToolsAndAddRes/AdditionalRes/Saving'}
            type="Collaborator Resources"
            link="Learn More"
          >
            <p>
            Figuring out smart ways to save and invest is one of the best ways to create financial stability. These resources can help you develop your saving strategy.
            </p>
          </FeaturedAsset>
        </div>

        <ReactMarkdown
          source={''}
          renderers={{ link: MarkLink }}
        />
      </Page>
    );
}
