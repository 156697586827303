/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import CalculatorRoutes from './calculator-routes';
import AdditionalResourceRoutes from './additional-resources-routes';
import NetWorthRoutes from './net-worth-routes';
import SenseRoutes from './sense-routes';
import MediaRoutes from './media-routes';
import ToolsTOC from '../components/tocs/toolsTOC';
import LinkModal from '../components/linkModal';
import Oops404Page from '../pages/oops-404';

export default function ToolsResourcesRoutes({ root, crumbs }) {
    const [showModal, setShowModal] = useState(false);
    const [modalSite, setModalSite] = useState('');

    const thisCrumbs = (crumbs ? `${crumbs},` : '') + 'Tools And Resources|';

    const setSite = (site) => {
        setShowModal(true);
        setModalSite(site);
    };

    const toc = () => (
      <ToolsTOC
        rootCalculators={`${root}/Calculators`}
        rootAdditionalRes={`${root}/AdditionalRes`}
        rootHowToVideos={`${root}/HowToVideos`}
        setSite={setSite}
      />
    );

    return (
      <div>
        <Switch>
          <Route path={`${root}/Calculators`}>
            <CalculatorRoutes crumbs={thisCrumbs} root={`${root}/Calculators`} toc={toc()} />
          </Route>

          <Route path={`${root}/AdditionalRes`}>
            <AdditionalResourceRoutes crumbs={thisCrumbs} root={`${root}/AdditionalRes`} toc={toc()} />
          </Route>

          <Route
            path={`${root}/NetWorth`}
            render={(renderProps) => <NetWorthRoutes {...renderProps} crumbs={thisCrumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/Sen$e`}
            render={(renderProps) => <SenseRoutes {...renderProps} crumbs={thisCrumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/Sense`}
            render={(renderProps) => <SenseRoutes {...renderProps} crumbs={thisCrumbs} toc={toc()} />}
          />

          <Route
            path={`${root}/ShareableResources`}
            render={(renderProps) => <MediaRoutes {...renderProps} crumbs={thisCrumbs} toc={toc()} />}
          />

          <Route render={() => <Oops404Page />} />
        </Switch>
        {
            showModal && (
              <LinkModal
                isSimulation
                dismiss={() => setShowModal(false)}
                href={modalSite}
              />
            )
          }
      </div>
    );
}
